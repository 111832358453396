import { useQuery } from '@tanstack/react-query';

import { PROFILE, PROFILE_ADDRESS } from '../../../shared/const/query.const';
import { getProfileData } from '../../../shared/services/profile.service';
import { ProfileDataType } from '../../../types/ProfilePageTypes';

export const useProfileQuery = () => {
  return useQuery({
    queryKey: [PROFILE],
    queryFn: () => getProfileData(),
    staleTime: 50000,
    select: (data: ProfileDataType) => data
  });
};

export const useProfileAddressQuery = () => {
  return useQuery({
    queryKey: [PROFILE_ADDRESS],
    queryFn: () => getProfileData(),
    staleTime: 50000,

    select: (data: ProfileDataType) => data.addresses
  });
};
