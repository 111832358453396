import { apiDeleteWithData, apiGetWithData, apiPost, apiPut } from '../../api/apiInstance';
import { AddToCartProps, ChangeDeliveryType, GeoTypeData, RemoveFromCartProps } from '../../types/CartPageTypes';
import { GeoType } from '../../types/CommonTypes';

export const getCart = async (params: GeoTypeData) => {
  const res = await apiGetWithData('/cart', params);
  return res.data;
};

export const addToCart = async (data: AddToCartProps) => {
  const { id, geo, quantity } = data;
  const res = await apiPost(`/cart/add/${id}`, { geo: geo, quantity: quantity });
  return res.data;
};

export const updateCartItem = async (data: AddToCartProps) => {
  const { id, geo, quantity } = data;
  const res = await apiPut(`/cart/update/${id}`, { geo: geo, quantity: quantity });
  return res.data;
};
export const removeFromCart = async (data: RemoveFromCartProps) => {
  const { id, geo } = data;

  const res = await apiDeleteWithData(`/cart/remove/${id}`, { geo: geo });
  return res.data;
};

export const clearCart = async (geo: GeoType) => {
  const res = await apiDeleteWithData(`/cart/remove-all`, { geo: geo });
  return res.data;
};

export const changeDeliveryType = async (data: ChangeDeliveryType) => {
  const { geo, delivery_type } = data;
  const res = await apiPost('/cart/delivery-type', { geo: geo, delivery_type: delivery_type });
  return res.data;
};

export const getPromocode = async (geo: GeoType, code: number) => {
  const res = await apiPost(`/cart/promocode`, { geo: geo, code: code.toString() });
  return res.data;
};
