export interface Theme {
  color: any;
}

const theme: Theme = {
  color: {
    bg: '#f2f4f7',
    bgMobile: '#EEF7F2',
    bgSecond: '#f1f1f1',
    text: '#000',
    titleColor: '#2b2b2b',
    textSecondary: '#231F20',
    white: '#fff',
    main: '#75af29',
    mainDisabled: '#C1D8A3',
    mainDark: '#0D933F',
    mainDarkSecondary: '#00822E',
    mainHover: '#5C9610',
    mainLight: '#E4EEDF',
    mainLightSecond: '#CBEAA3',
    mainLightHover: '#E67029',
    mainLightHoverSecond: '#EEF7F2',
    mainLightHoverThird: '#CBEAA3',
    chatBg: '#ecf7dd',
    grey: '#c7c7c7',
    darkGrey: '#525252',
    greyA0: '#a0a0a0',
    greyLight: '#afa8a8',
    greyMedium: '#928F8F',
    greyE7: '#e7e7e7',
    greyE8: '#e8e8e8',
    greyC3: '#c3c3c3',
    greyAA: '#AAAAAA',
    grey6E: '#6E6E6E',
    grey85: '#858585',
    grey66: '#666666',
    greyEC: '#ececec',
    greyEB: '#ebebeb',
    borderInput: '#ececec',
    dropdownColor: '#131313',
    danger: '#ff5f00',
    orange: '#F9833C',
    orangeLight: '#F37021',
    inputCountBg: '#EAECF0',
    searchBorderHover: '#00822E',
    boxShadow: '#0000001A',
    toggleInactiveBg: '#D4D5D9',
    modalOverlayBg: '#00000059',
    disabledColor: '#C5C5C5',
    errorBorder: '#E57524',
    errorText: '#AC3C00',
    notFoundBorder: '#AF202A',
    notFoundText: '#CF111D',
    searchMobileBg: '#FFFFFF99',
    searchMobileBtnBg: '#FFFFFF59',
    bgCancelButton: '#CBEAA3',
    disabledLinkBg: '#57986F',
    disabledLinkColor: '#CBCBCB'
  }
};

export default theme;
