import { useQuery } from '@tanstack/react-query';
import { CART } from '../../../shared/const/query.const';
import { getCart } from '../../../shared/services/cart.service';
import { CartDataType, GeoTypeData } from '../../../types/CartPageTypes';

export const useCartQuery = (data: GeoTypeData) => {
  return useQuery<CartDataType>({
    queryKey: [CART, data],
    queryFn: () => getCart(data),
    staleTime: 1800000,
    select: (data: CartDataType) => data || []
  });
};
