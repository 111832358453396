import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PROFILE, PROFILE_ADDRESS } from '../../../shared/const/query.const';
import { addUserAddress, deleteUserAddress, updateUserAddress, updateUserData } from '../../../shared/services/profile.service';
import { ProfileUpdateAddressData, ProfileUpdateDataType } from '../../../types/ProfilePageTypes';

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ProfileUpdateDataType) => updateUserData(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PROFILE] });
      }
    }
  });
};

export const useAddAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ProfileUpdateAddressData) => addUserAddress(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PROFILE_ADDRESS] });
      }
    }
  });
};

export const useUpdateAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ProfileUpdateAddressData) => updateUserAddress(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PROFILE_ADDRESS] });
      }
    }
  });
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteUserAddress(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PROFILE_ADDRESS] });
      }
    }
  });
};
