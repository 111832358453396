import { Link } from 'react-router-dom';

import { styled } from 'styled-components';

export const FlexWithAlign = styled.div<{
  $align?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  $justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  $gap?: string;
  $column?: boolean;
  $position?: string;
}>`
  display: flex;
  position: ${(props) => props.$position || 'relative'};
  gap: ${(props) => props.$gap || '24px'};
  flex-direction: ${(props) => (props.$column ? 'column' : 'row')};
  align-items: ${(props) => props.$align};
  justify-content: ${(props) => props.$justify};
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 50px;
  @media (max-width: 1280px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 420px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const MainTitle = styled.h2`
  padding: 10.5px 0;
  margin-bottom: 20px;
  color: ${(props) => props.theme.color.titleColor};
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1280px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SecondTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const Title22 = styled.h3`
  font-size: 22px;
  font-weight: 700;
  line-height: 26.6px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21.76px;
  }
`;
export const ImgWrap = styled.img<{
  $borderRadius?: string;
}>`
  border-radius: ${(props) => (props.$borderRadius ? props.$borderRadius : '20px')};
  @media (max-width: 768px) {
    border-radius: 8px;
    max-height: 180px;
  }
`;

export const Text12 = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.color.text};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

export const Text15 = styled.span`
  color: ${(props) => props.theme.color.textSecondary};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
`;

export const TextBold = styled.span`
  color: ${(props) => props.theme.color.text};
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
`;

export const Text17 = styled.span`
  color: ${(props) => props.theme.color.titleColor};
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
`;

export const Text18 = styled.span`
  color: ${(props) => props.theme.color.text};
  font-size: 18px;
  line-height: 20.88px;
`;
export const TextBold18 = styled.span`
  color: ${(props) => props.theme.color.titleColor};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
`;
export const TextBold20 = styled.span`
  color: ${(props) => props.theme.color.text};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const CardLink = styled(Link)`
  display: flex;
  padding: 18px;
  flex-direction: column;
  gap: 22px;
  position: relative;
  border: 2px solid transparent;
  border-radius: 20px;
  background-color: ${(props) => props.theme.color.white};
  height: 100%;
  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.color.mainDark};
    background-color: ${(props) => props.theme.color.mainLight};
  }
  img {
    max-height: 206px;
    min-height: 206px;
  }
  @media (max-width: 1280px) {
    gap: 15px;
  }
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 50%;
  width: 52px;
  color: ${(props) => props.theme.color.mainDark};
  background-color: ${(props) => props.theme.color.mainLight};
  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.white};
    background-color: ${(props) => props.theme.color.mainDark};
  }
  @media (max-width: 1280px) {
    padding: 8px;
    width: 36px;
    svg {
      width: 8px;
    }
  }
`;

export const Text16 = styled.span<{
  $color?: string;
}>`
  color: ${(props) => (props.$color ? props.$color : props.theme.color.text)};
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
`;

export const Text14 = styled.span<{
  $color?: string;
}>`
  color: ${(props) => (props.$color ? props.$color : 'inherit')};
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

export const StatusLabel = styled.span`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) => props.theme.color.grey66};
`;
