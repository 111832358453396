import { ChangeEvent, useEffect, useState } from 'react';

import { ButtonMinus, ButtonPlus, ProductButtonInput, ProductButtonWrapper } from './ProductButton.styled';

import { useAddToCart, useRemoveFromCart, useUpdateCartItem } from '../../../../hooks/mutations/cart/useCartMutations';
import { GEO } from '../../../../shared/const/common.const';
import { CatalogProduct } from '../../../../types/CatalogPageTypes';
import { EIcon, Icon } from '../../../icons/icon';
import { roundQuantity } from '../../../../utils/helperFunctions';

interface IProductButtonProps {
  product: CatalogProduct | any;
  showCount?: boolean;
  className?: string;
}

const ProductButton: React.FC<IProductButtonProps> = ({ product, showCount = true, className }) => {
  const [value, setValue] = useState<string>(product.quantity);
  const { mutate: add } = useAddToCart();
  const { mutate: update } = useUpdateCartItem();
  const { mutate: remove } = useRemoveFromCart();

  const quantityStep =
    product && product.product && product.product.weight_step ? Number((product.product.weight_step / 1000).toFixed(2)) : 1;
  const handleIncrease = () => {
    add({ id: product.product_id, geo: { lat: '59.915485', lon: '30.344982' }, quantity: quantityStep });
  };
  const handleDecrease = () => {
    if (product.quantity === quantityStep.toString()) {
      remove({ id: product.product_id.toString(), geo: GEO });
    } else {
      update({ id: product.product_id, geo: { lat: '59.915485', lon: '30.344982' }, quantity: Number(product.quantity) - quantityStep });
    }
    setValue((prevState) => (Number(prevState) - quantityStep).toString());
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value;

    const validValue = inputValue.replace(/[^0-9.]/g, '');
    setValue(validValue);
  };
  const handleBlur = () => {
    const roundQty = roundQuantity(value, quantityStep);
    setValue((prevState) => roundQuantity(prevState, quantityStep));
    update({ id: product.product_id, geo: { lat: '59.915485', lon: '30.344982' }, quantity: Number(roundQty) });
  };
  useEffect(() => {
    setValue(product.quantity);
  }, [product]);
  return (
    <ProductButtonWrapper className={className ? className : 'default'}>
      <ButtonMinus
        onClick={handleDecrease}
        className='flex'
        type={'button'}
      >
        <Icon name={EIcon.minus} />
      </ButtonMinus>

      <span className='input-count-wrap'>
        {/* <CountPicker
          onChange={setCount}
          value={count}
        /> */}
        <ProductButtonInput
          type='text'
          value={value}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          inputMode='numeric'
        />
        <span className='unit'>{product.product.product.type && product.product.product.type === 'weight' ? 'кг' : 'шт'}</span>
      </span>
      <ButtonPlus
        onClick={handleIncrease}
        className='flex'
        type={'button'}
      >
        <Icon name={EIcon.plus} />
      </ButtonPlus>
    </ProductButtonWrapper>
  );
};

export default ProductButton;
