import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CART } from '../../../shared/const/query.const';
import { addToCart, changeDeliveryType, clearCart, removeFromCart, updateCartItem } from '../../../shared/services/cart.service';
import { AddToCartProps, ChangeDeliveryType, RemoveFromCartProps } from '../../../types/CartPageTypes';
import { GeoType } from '../../../types/CommonTypes';

export const useAddToCart = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AddToCartProps) => addToCart(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CART] });
      }
    }
  });
};

export const useUpdateCartItem = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AddToCartProps) => updateCartItem(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CART] });
      }
    }
  });
};
export const useRemoveFromCart = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: RemoveFromCartProps) => removeFromCart(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CART] });
      }
    }
  });
};

export const useClearCart = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: GeoType) => clearCart(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CART] });
      }
    }
  });
};

export const useChangeDeliveryType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ChangeDeliveryType) => changeDeliveryType(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CART] });
      }
    }
  });
};
