import { styled } from 'styled-components';
import { opacityAnim, openMoveXTop } from '../../../../utils/animations';

export const HeaderWrapper = styled.header`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: ${(props) => props.theme.color.main};
  .header-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .search-form {
    width: 100%;
    &.small {
      max-width: 302px;
      .search-result {
        min-width: 510px;
        left: 50%;
        transform: translateX(-50%);
        animation: ${openMoveXTop} 0.3s ease;
        &.example-enter {
          transform: translate(-50%, 20px);
        }
        &.example-enter-active {
          transform: translate(-50%, 0);
        }
        &.example-exit {
          transform: translate(-50%, 0);
        }
        &.example-exit-active {
          transform: translate(-50%, 20px);
        }
        @media (max-width: 970px) {
          min-width: 343px;
        }
      }
    }
  }
  .header-content.with-shop-name {
    .search-form {
      width: auto;
      margin-right: auto;
    }
  }
  .header-mini-cart {
    margin-left: auto;
  }
  .header-address-dropdown {
    min-width: 240px;
    max-width: 240px;
    margin-left: auto;
    .react-select {
      width: 100%;
    }
    .custom-select__single-value {
      color: ${(props) => props.theme.color.mainDark};
    }
    .custom-select__indicators {
      display: none;
    }
    .custom-select__value-container {
      padding-right: 20px;
    }
    .custom-select__menu {
      margin: 0;
      color: ${(props) => props.theme.color.text};
      font-size: 16px;
      line-height: 20px;
      border-radius: 12px;
      overflow: hidden;
    }
    .custom-select__option {
      position: relative;
      min-height: 24px;
    }
    .add-address-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: ${(props) => props.theme.color.mainDark};

      font-size: 14px;
    }
  }
  @media (max-width: 1280px) {
    background-color: transparent;
    .header-container {
      background-color: ${(props) => props.theme.color.main};
    }
    .header-address-dropdown {
      max-width: none;
    }
    .custom-select__control {
      border-radius: 0;
      border: none;
      background-color: transparent;
      box-shadow: none;
      &--menu-is-open,
      &--is-focused {
        box-shadow: none;
        border-color: transparent;
      }
    }
  }
  @media (max-width: 1024px) {
    .header-content.with-shop-name {
      .header-page-name {
        margin-left: auto;
      }
    }
    .search-form {
    }
  }
  @media (max-width: 768px) {
    .header-container {
      padding-top: 13px;
      padding-bottom: 13px;
    }
    .header-content {
      gap: 7px;
      &.with-shop-name {
        .header-page-name {
          margin-left: auto;
          order: 1;
          flex-grow: 1;
        }
        .search-form {
          order: 2;
          flex-grow: 1;
        }
        .header-mini-cart {
          order: 3;
          margin-left: 0;
        }
      }
    }
    .search-form--header {
      position: absolute;
      left: 10px;
      right: 43px;
      width: auto;
      background-color: ${(props) => props.theme.color.main};
      animation: ${opacityAnim} 0.3s ease;
      z-index: 5;
      .search-form {
        max-width: none;
      }
    }
    .mobile-container {
      padding: 0;
      background-color: ${(props) => props.theme.color.bg};
    }
    .header-address-dropdown {
      padding-left: 15px;
      padding-right: 15px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &.small {
      .search-result {
        min-width: 343px;
      }
    }
  }
  @media (max-width: 420px) {
    .header-container {
      padding-left: 4px;
    }
    .search-form--header {
      gap: 8px;
      right: 48px;
    }
  }
`;

export const LogoWrap = styled.div`
  display: flex;
  margin-right: 2.75rem;
  svg {
    width: 100px;
    height: 84px;
  }
  @media (max-width: 1280px) {
    display: none;
  }
`;

export const BurgerWrap = styled.div`
  display: none;
  @media (max-width: 1280px) {
    display: flex;
  }
`;

export const BackLinkNav = styled.nav`
  min-width: 129px;
  .back-link {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 18px 20px;
    min-height: 52px;
    border-radius: 32px;
    background-color: ${(props) => props.theme.color.mainDark};
    color: ${(props) => props.theme.color.white};
    font-size: 14px;
    line-height: 16px;
    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.color.mainDarkSecondary};
    }
  }
  @media (max-width: 970px) {
    display: none;
  }
`;

export const BackLinkNavIconWrap = styled.span`
  display: flex;
  max-width: 16px;
`;

export const PageNameWrap = styled.div`
  min-width: 100px;
  flex-shrink: 0;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: ${(props) => props.theme.color.white};
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const ShowSearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.theme.color.searchMobileBtnBg};
  color: ${(props) => props.theme.color.white};
  &.hide-search-btn {
    svg {
      transform: rotate(-90deg);
    }
  }
  @media (max-width: 768px) {
    order: 2;
  }
`;
